export default class HistoricoMargemService {
  constructor(http) {
    this._http = http
  }

  async getListaHistoricoMargemPorMatricula(id) {
    const { data } = await this._http.get(
      `/api/historico_margem/matriculo/${id}`,
    )
    return data
  }

  async getUltimoHistoricoMargem(id) {
    const { data } = await this._http.get(
      `/api/historico_margem/ultimo-historico-margem/matricula/${id}`,
    )
    return data
  }

  async getUltimoHistoricoMargemConsignataria(id) {
    const { data } = await this._http.get(
      `/api/historico_margem/ultimo-historico-margem/consignataria/matricula/${id}`,
    )
    return data
  }
}
