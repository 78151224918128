var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12"},[_c('Card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DataTable',{attrs:{"value":_vm.historicos_margem,"stripedRows":"","paginator":true,"rows":5,"loading":_vm.loading,"totalRecords":_vm.totalRecords,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":_vm.rowsPerPageOptions,"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} históricos de margem","responsiveLayout":"scroll"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhum histórico de margem encontrado. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true}])},[_c('Column',{attrs:{"field":"mesReferencia","header":"Mês/Ano"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(('0' + data.mesReferencia).slice(-2))+"/"+_vm._s(data.anoReferencia)+" ")]}}])}),_c('Column',{attrs:{"header":_vm.labelMargem('margem6')},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.margem6))+" ")]}}])}),_c('Column',{attrs:{"header":_vm.labelMargem('margem5')},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.margem5))+" ")]}}])}),_c('Column',{attrs:{"header":_vm.labelMargem('margem4')},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.margem4))+" ")]}}])}),_c('Column',{attrs:{"header":_vm.labelMargem('margem3')},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.margem3))+" ")]}}])})],1)]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }