<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <DataTable
            :value="historicos_margem"
            stripedRows
            :paginator="true"
            :rows="5"
            :loading="loading"
            :totalRecords="totalRecords"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} históricos de margem"
            responsiveLayout="scroll">
            <template #empty> Nenhum histórico de margem encontrado. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <Column field="mesReferencia" header="Mês/Ano">
              <template #body="{ data }">
                {{ ('0' + data.mesReferencia).slice(-2) }}/{{
                  data.anoReferencia
                }}
              </template>
            </Column>
            <Column :header="labelMargem('margem6')">
              <template #body="{ data }">
                {{ data.margem6 | formatarValor }}
              </template>
            </Column>
            <Column :header="labelMargem('margem5')">
              <template #body="{ data }">
                {{ data.margem5 | formatarValor }}
              </template>
            </Column>
            <Column :header="labelMargem('margem4')">
              <template #body="{ data }">
                {{ data.margem4 | formatarValor }}
              </template>
            </Column>
            <Column :header="labelMargem('margem3')">
              <template #body="{ data }">
                {{ data.margem3 | formatarValor }}
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import HistoricoMargemService from '@/service/HistoricoMargemService.js'
import { servidorStore } from '@/stores/servidor'

export default {
  setup() {
    const store = servidorStore()
    return { store }
  },

  data() {
    return {
      historicos_margem: [],
      historicoMargem: {},
      loading: false,
      totalRecords: 0,
      parametros: {},
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  created() {
    this.historicoMargemService = new HistoricoMargemService(this.$http)
  },

  mounted() {
    this.loading = true
    this.historicoMargemService
      .getListaHistoricoMargemPorMatricula(this.$route.params.id)
      .then((res) => {
        this.historicos_margem = res
        this.loading = false
      })
      .catch((err) => {
        console.log(err)
        this.loading = false
        this.$toast.add({
          severity: 'error',
          summary: 'Ocorreu um erro. Tente novamente!',
          life: 10000,
        })
      })
  },

  methods: {
    labelMargem(chave) {
      let label = this.store.dados.configuracoes?.find(
        (el) => el.chave === chave,
      )
      if (label) {
        return label?.valor
      }
      return chave
    },

    onPage(event) {
      this.parametros = event
    },

    formatarData(value) {
      if (!value) return ''
      return new Date(value).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
